/* ====== ROOT ======*/

@font-face {
  font-family: "Party";   /*Can be any text*/
  src: local("Party"),
    url("./fonts/sk-akademkniga-trial.ttf") format("truetype");
}

/* ====== ANIMATIONS ======*/

@keyframes tab-height {
  0% {
    height: 0px;
  }
  to {
    height: 50px;
  }
}

@keyframes tab-close {
  0% {
    height: 50px;
  }
  to {
    height: 0px;
  }
}

.tab-height {
  animation: tab-height forwards 2s;
}

.tab-close {
  animation: tab-close forwards 1s;
}

@keyframes dropdown {
  0% {
    height: 0px;
  }
  to {
    height: 50px;
  }
}

@keyframes tab-close {
  0% {
    height: 50px;
  }
  to {
    height: 0px;
  }
}

.tab-height {
  animation: tab-height forwards 2s;
}

.tab-close {
  animation: tab-close forwards 1s;
}

.dropdown-menu {
  animation: dropdown forwards 2s;
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
/* ====== COLORS ====== */

:root {
  --bubblegum: #ffb2f3;
  --light-pink: #ffdff2;
  --red: #E84338;
  --ivy: #46BE77;
  --royal-blue: #437CD6;
  --off-black: #323232;
  --lavendar: #C794F9;
  --orange: #FB7A36;
  --light-grey: #BFBDBE;
  --cherry: #A42B36;
  --grape-soda: #AE5F99;
  --lemon: #FACA2A;
  --electric-blue: #003AEE;
  --app-height: 100%;
}

.bubblegum{
  color: var(--bubblegum);
}

.bg-bubblegum {
  background-color: var(--bubblegum);
}

.light-pink {
  color: var(--light-pink);
}

.bg-light-pink {
  background-color: var(--light-pink);
}

.bg-ivy-gradient {
  background: linear-gradient(290deg, white 0%, white 26%, var(--ivy) 20%);
}

.ivy-text {
  color: var(--ivy);
}

.bg-ivy {
  background-color: var(--ivy);
}

.lemon-text {
  color: var(--lemon)
}

.bg-royal-blue {
  background-color: var(--royal-blue);
}

.bg-royal-blue-gradient {
  background: linear-gradient(290deg, white 0%, white 26%, var(--royal-blue) 20%);
}

.royal-blue-text {
  color: var(--royal-blue)
}

.orange-text {
  color: var(--orange);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-orange-gradient {
  background: linear-gradient(290deg, white 0%, white 26%, var(--orange) 20%);
}

.bg-lemon {
  background-color: var(--lemon);
}

.bg-lemon-gradient {
  background: linear-gradient(290deg, white 0%, white 20%, var(--lemon) 20%);
}

.bg-lavendar {
  background-color: var(--lavendar);
}

.bg-lavendar-gradient {
  background: linear-gradient(290deg, white 0%, white 26%, var(--lavendar) 20%);
}

.bg-grape-soda {
  background-color: var(--grape-soda);
}

.bg-grape-soda-gradient {
  background: linear-gradient(290deg, white 0%, white 26%, var(--grape-soda) 20%);
}

.bg-off-black {
  background-color: var(--off-black);
}

.border-bttm-off-black {
  border-bottom: 2px solid var(--off-black);
}

.border-top-off-black {
  border-top: 1px solid var(--off-black);
}

.border-bttm-off-black-hover:hover {
  border-bottom: 2px solid var(--off-black);
}

.grape-soda-text {
  color: var(--grape-soda);
}

.ivy:hover{
  background-color: var(--ivy);
}

.lemon:hover{
  background-color: var(--lemon);
}

.royal-blue:hover{
  background-color: var(--royal-blue)
}

#royal-blue:checked{
  background-color: var(--royal-blue)
}

.orange:hover{
  background-color: var(--orange);
}

.grape-soda:hover{
  background-color: var(--grape-soda);
}

#lavendar:hover{
  background-color: var(--lavendar);
}

body {
  padding: 0;
  margin: 0;
}

/* ====== FONTS ====== */

h1 {
  color: var(--off-black);
  font-family: nimbus-sans-extended, sans-serif;
  font-size: 3em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
}

h2 {
  font-family: nimbus-sans-extended, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

h2 a {
  font-family: nimbus-sans-extended, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

h3 {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0;
}

h4 {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: italic;
  font-size: 1em !important;
}

h5 {
  font-family: anonymous-pro, monospace;
  font-weight: 700;
  font-style: normal;
}

.bigger-h4 {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: italic;
  font-size: 1.5em !important;
}

p {
  font-family: nimbus-sans-extended, sans-serif;
  font-weight: 400;
  font-style: normal;
}

p a {
  color: var(--electric-blue);
}

p a:hover {
  color: var(--royal-blue);
}

a:hover {
  color: var(--electric-blue);
}

p a:active {
  color: var(--bubblegum);
}

li {
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  margin-left: 30px;
}

ul {
  padding: 0px;
  margin: 0px;
}

.page-title {
  text-align: left;
  margin: 0;
  padding: 0vH 2vW;
  font-size: 5em;
  background-color: var(--off-black);
  display: inline-block;
  border-radius: 0px 0px 10px 10px;
}

.portfolio-page-title {
  text-align: left;
  margin-left: 17.5vW;
  margin-top: 0;
  padding: 0vH 2vW;
  background-color: var(--off-black);
  display: inline-block;
  border-radius: 0px 0px 10px 10px;
}

.big-text {
  font-size: 1.25em;
}

.intro-text {
  font-size: 3em;
  text-align: center;
}

.no-hyperlink-style {
  color: var(--off-black);
  text-decoration: none;
}

.no-hyperlink-style:hover {
  color: var(--electric-blue);
}
/* ====== SPACING ====== */

/* === DISPLAY ====*/

#mobile {
  display: none;
}

/* === POSITION ====*/

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-index-10 {
  z-index: 10;
}

/* === GRID ===*/

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, auto [col-start]) 85vW [col-video] repeat(5, auto [col-start]);
  /* grid-template-columns: repeat(21, auto [col-start]); */
  grid-template-rows: [row-start] auto [row] repeat(18, auto [row]) auto [row-end];
  margin-left: 0;
  margin-right: 0;
}

.item {
  border: 1px solid var(--off-black);
}

.item-a {
  grid-column: col-video / 6;
  grid-row: row-start / row-end;
  justify-self: stretch;
  width: 85vW;
  height: 47.8125vW;
}

.case-studies-video-player {
  width: 85vW;
  height: 48vW;
}

.case--studies--container {
  padding: 1.5em 7.5vW;
}

.case--studies--container * + * {
	margin-top: 1.5em;
}

.case--studies--container--small * + *  {
  margin-top: 0;
}

/* === FLEXBOX ====*/

.standard-spacing {
  padding: 0vH 7.5vW 2vH 7.5vW;
}

/* we need to shift the pages so that the padding isn't associated with the border */

.top-page-border {
  border-top: 2px solid var(--off-black);
}

.standard-padding {
  padding: 0vH 7.5vW 2vH 7.5vW;
}

/* KEEP  */

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

/* Start phasing in  */

.jc-center {
  justify-content: center;
}

.jc-baseline {
  align-items: baseline;
}

.al-center {
  align-items: center;
}

.jc-space-btwn {
  justify-content: space-between;
}

/* Start phasing out  */

.start {
  justify-content: flex-start;
}

.end {
  justify-content: flex-end;
}

.column {
  flex-direction: column;
}

.center {
  justify-content: center;
}

.col-center {
  align-items: center;
}

.col-end {
  align-items: end;
}

.space-btwn {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

/* === HEIGHT & WIDTH === */

.full-width {
  width: 100%;
}

.half {
  width: 50%;
}

.forty-eight-percent-w {
  width: 48%;
}

.forty-eight-percent-w-mobile {
  width: 48%;
}

.twenty-two-percent-w {
  width: 22%;
}

.height-one-hundred {
  height: 100%;
}

.full-height {
  height: 90vH;
}

.min-height-90vH {
  min-height: 90vH;
}

.third {
  width: 33vW;
}

.scroll {
  overflow: scroll;
}

/* === PADDING & MARGIN === */

.zero-margin {
  margin: 0;
}

.padding {
  padding: 1vH 2vW;
}

.no-space {
  padding: 0;
  margin: 0;
}

.top-padding-2vH {
  padding-top: 2vH;
  padding-bottom: 0;
}

.side-padding-2vW {
  padding-left: 2vW;
  padding-right: 2vW;
}



/* === NAVIGATION BAR ===*/

.menu-container {
  background-color: var(--bubblegum);
  height: auto;
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 999;
  top: 0px;
}

.menu-container h1 {
  border-bottom: 2px solid var(--off-black);
}

.menu-container a {
  text-decoration: none;
}

.menu-icon {
  width: 40px;
  height: 40px;
  overflow: visible;
  cursor: pointer;
}

.wordmark {
  font-family: 'oswald';
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  margin: 0px;
  color: var(--light-pink);
}

.menu {
  font-family: 'oswald';
  font-weight: 400; 
  font-style: normal;
  font-size: 24px;
  margin: 0px 10px;
  color: var(--light-pink);
}

.cls-1 {
  transition: all 300ms ease-out;
  transform-origin: 50% 50%;
}

.cls-1:hover {
  animation-play-state: running;
}

.menu-header {
  background-color: var(--off-black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid var(--off-black);
  height: 50px;
}

.menu-toggle {
  display: flex;
  align-items: center;
}

.contact-social-icon {
  fill: var(--off-pink)
}

.nav-position {
  position: relative;
  height: 100%;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
  position: absolute;
  z-index: 100;
  background-color: var(--bubblegum);
}

.menu-list-container {
  background-color: var(--bubblegum);
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  width: 60%;
}

.contact-container {
  border: 1px solid var(--off-black);
  border-right: none;
  background-color: var(--off-black);
  border-radius: 10px 0px 0px 10px;
  height: 30%;
  margin-top: 40px;
  text-align: right;
  padding: 40px 0px;
  width: 25%;
  overflow: hidden;
}

.contact-container h5 {
  font-family: nimbus-sans-extended, sans-serif;
  font-size: 16px;
  margin: 5px 30px;
  text-decoration: underline;
  text-decoration-color: var(--light-grey);
  color: var(--light-pink);
}

.contact-container h5:hover {
  cursor: pointer;
  color: var(--bubblegum);
}

.social-icons-container {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}

/* === Design Partners ===*/

.design-partners-display {
  display: flex;
  justify-content: space-around;
}

.design-partner {
  display: flex;
  flex-direction: column;
  width: 35vW;
}

.portrait {
  /* fThis will become a mask */
  border-radius: 30px;
  width: 50%;
  height: auto;
}

/* === FOOTER ===*/

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vH 0vW;
  background-color: var(--light-pink);
}

.footer p {
  margin: 0;
}

.social-media-logo {
  margin-left: 10px;
  width: 30px;
}

.newsletter-form {
  width: 60vW;
  border: 1px solid var(--off-black);
  border-radius: 10px;
  padding: 2vH 3vW;
}

.newsletter-input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid var(--off-black);
  background-color: transparent;
  outline: none;
  display: flex;
  margin-top: 1vH;
}

.form-spacing{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 54vW;
}

.newsletter-input::placeholder {
  color: var(--off-black);
}

.newsletter-input[type="text"] {
  color: var(--off-black);
}

.newsletter-input:focus {
  border-bottom: 1px solid var(--royal-blue);
}

.footer-button {
  background-color: var(--off-black);
  padding: 1vH 1vW;
  border: none;
  border-radius: 5px 5px 5px 5px;
  text-transform: uppercase;
  font-family: nimbus-sans-extended, sans-serif;
  color: var(--light-pink);
  margin-top: 1vH;
}

.footer-button:hover {
  color: var(--royal-blue)

}
/* === PORTFOLIO ===*/

#images {
  display: none;
}
.portfolio-index {
  width: 90vW;
}

.portfolio-title {
  border-left: 1px solid var(--off-black);
  border-right: 1px solid var(--off-black);
  border-bottom: 1px solid var(--off-black);
  width: 90vW;
  height: 100%;
  transition: height 800ms ease-in-out;
  text-align: left;
  padding: 2vH 2vW;
  overflow: hidden;
}

/* .portfolio-title:hover {
  height: 20vH;
  transition: height 800ms ease-in-out;
} */

.portfolio-tab {
  height: 100%;
  width: 3vW;
  margin-right: 3vW;
}

.portfolio-text {
  width: 55vW;
}

.portfolio-bttn:hover {
  cursor: pointer;
}

.portfolio-img {  
  width: 65vW;
  border-radius: 10px;
  border: 10px solid var(--off-black);
  /* box-shadow: 3px 2px 3px 1px var(--off-black); */
}

.portfolio-bttn {
  background-color: var(--off-black);
  border: none;
  color: var(--light-pink);
  border-radius: 10px;
  font-size: 4em;
}

#mirror {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.portfolio-bttn:hover {
  color: var(--bubblegum);
}

.CPR-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60vW;
  margin: 8vH 0vW;
}

.portfolio-logo {
  max-width: 10vW;
  margin-top: 2vH;
}

.portfolio-logo-lg {
  max-width: 45vW;
}

.portfolio-comp {
  display: flex;
}

.portfolio-desc {
  margin: 0vH 0vW 0vH 0vW;
  width: 65vW;
  padding: 0vH 2vW 1vH 2vW;
  border-radius: 30px;
}

.last {
  border-bottom: 1px solid var(--off-black);
}

#first {
  border-top: 1px solid var(--off-black);
}
/* === SERVICES ===*/

.services-page {
  margin-top: 5vH;
  width: 85vW;
  height: 90vH;
  border: 1px solid var(--off-black);
  display: flex;
}

.service-list {
  width: 30vW;
  border-right: 1px solid var(--off-black);
}
.service {
  padding: 1vH 0vW 1vH 1vW;
  border-bottom: 1px solid var(--off-black);
}

.service:hover{
  cursor: pointer;
}

.cursor:hover {
  cursor: pointer;
}

.table-description{
  width: calc(55vW - 1px);
  min-height: 100%;
}

.table-heading {
  background-color: var(--off-black);
  padding: 0vH 2vW 0vH 2vW;
  margin: 0;
}

.table-heading h3 {
  margin: 0;
}

.branding-intro {
  background-color: var(--off-black);
  padding: 2vH 2vW 1vH 2vW;
  border-radius: 30px;
}

.print {
  font-size: 4em;
  color: var(--off-black);
}

/* .print:hover {
  color: var(--light-pink)
} */

/* === CONTACT ===*/

.contact-form {
  border: 1px solid var(--off-black);
  border-radius: 20px;
  margin-top: 5vH;
  padding: 5vH 8vW;
  display: flex;
  background-color: var(--off-black);
}

.contact-input {
  background-color: transparent;
  margin-top: 2vH;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--bubblegum);
  width: 30vW;
  outline: none;
}

.contact-input:focus {
  border-bottom: 1px solid var(--ivy);

}

.contact-info {
  display: flex;
  justify-content: space-between;
  width: 74vW;
}
input::placeholder{
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  color: var(--bubblegum)
}

input[type=text]{
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  color: var(--bubblegum);
}

input[type="checkbox"] {
  background-color: var(--bubblegum);
}

.textarea-input{
  background-color: transparent;
  margin-top: 2vH;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--bubblegum);
  width: 100%;
  outline: none;
  height: 20vH;
  margin-bottom: 0;
  resize: none;
}

textarea[type=text]{
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  color: var(--bubblegum);
}

textarea[type=text]:focus{
  border-bottom: 1px solid var(--ivy);

}
textarea::placeholder{
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  color: var(--bubblegum);
}

.checkboxes{
  margin: 2vH 0vW;
  display: flex;
  flex-direction: column;
}
.checkbox-name{
  font-family: anonymous-pro, monospace;
  font-weight: 400;
  font-style: normal;
  color: var(--bubblegum);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 2vH;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50%;
}

.checkbox-name:hover input ~ .checkmark{
  background-color: var(--light-pink);
}

.checkbox-name input:checked ~ .checkmark {
  background-color: var(--ivy);
}
.checkbox-name input {
  display: flex;
  justify-content: center;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  margin-right: 2vW;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  position: relative;
  top: 0px;
  left: 0;
  height: 10px;
  width: 20px;
  border-radius: 15px;
  background-color: var(--bubblegum);
}

.checkmark:after {
  color: white;
  content: "";
  position: absolute;
  display: none;
}

.checkbox-name input:checked ~ .checkmark:after {
  display: block;
  text-align: center;
}

.container .checkmark:after {
  background-color: var(--main-red);
}

.form-button {
  margin-top: 2vH;
  background-color: var(--bubblegum);
  padding: 2vH 8vW;
  border: none;
  border-radius: 0px 0px 5px 5px;
  text-transform: uppercase;
  font-family: nimbus-sans-extended, sans-serif;
  color: var(--off-black)
}

.form-button:hover{
  background-color: var(--ivy);
}


/* === CONNECT ===*/

.community-block{
  width: 40vW;
}

.collaborations-block{
  width: 40vW;
}

.connect-form {
  border: 1px solid var(--off-black);
  border-radius: 20px;
  padding: 5vH 2vW;
  display: flex;
  background-color: var(--off-black);
}

/* === STEERING COMMITTEE === */

.SC-container {
  width: 80%;
}

.SC-member {
  border-top: 1px solid var(--off-black);
  border-radius: 0 30px 0 0;
  margin-top: 2vH;
}

.bio {
  width: 50%;
  padding-right: 2vW;
}

.section-heading {
  background-color: var(--off-black);
  color: var(--light-pink);
  padding: 0vH 7.5vW 0vH 7.5vW;
  margin: 0;
}

.section-heading h3 {
  margin: 0;
}

.side-column {
  width: 20%;
  right: 7.5vW;
}

.SC-info {
  border-left: 1px solid var(--off-black);
}

.SC-info h3 {
  font-size: 20px;
  background-color: var(--off-black);
  color: var(--light-pink);
  padding-left: 1vW;
  margin-bottom: 0;
}

.SC-portrait {
  width: 100%;
  border-radius: 30px;
}

.SC-info p {
  padding-left: 1vW;
}

.extra-margin {
  margin-top: 5vH;
}

.intro {
  margin: 5vH 0 2vH 0 !important;
}

.info-component {
  border: 1px solid var(--off-black);
  border-radius: 0px 0px 10px 10px;
  position: sticky;
  top: 0%;
  left: 20px;
  background: var(--light-pink);
  display: inline-block;
  z-index: 10;
  margin-left: 20px;
}

.info-component h3 {
  font-size: 20px;
  background-color: var(--off-black);
  color: var(--light-pink);
  padding-left: 1vW;
}

.info-component p {
  padding-left: 1vW;
}
/* === SUPPORT === */

.support-bttn {
  border: 1px solid var(--off-black);
  border-radius: 30px;
  width: 30%;
  padding: 2vH 1vW 2vH 1vW;
}

.support-bttn h3 {
  font-size: 20px;
  margin: 0;
}

.support-bttn p {
  margin: 0;
}

.support-bttn:hover {
  background-color: var(--off-black);
  color: var(--bubblegum);
}

.support-line {
  border-right: 1px solid var(--off-black);
}

.support-div {
  height: 20vH;
  width: 50%;
}

.patreon-tiers {
  width: 75vW;
  background-color: var(--off-black);
  border-radius: 30px;
  padding: 4vH 2vW 4vH 2vW;
}

.patreon-tier {
  background-color: var(--bubblegum);
  border-radius: 30px;
  width: 15vW;
  padding: 2vH 1vW 2vH 1vW;
  margin-top: 2vH;
}

.patreon-tier h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.patreon-tier p {
  margin: 0;
}

.patreon-bttns {
  margin-top: 2vH;
}

.patreon-bttn {
  display: block;
  height: auto;
  text-decoration: none;
  color: var(--off-black)
}

.perks {
  padding-left: 15px;
  margin-top: 0;
}

.asterix-padding {
  width: 75vW;
  padding: 3vH 0vW;
}

/* === PROGRAMS === */

.table-padding {
  padding: 2vH 2vW;
}

.mentorship-letter {
  background-color: white;
  padding: 2vH 2vW 1vH 2vW;
  border-radius: 30px;
}

.party-performer {
  border-top: 1px solid var(--off-black);
}

.party-performer p {
  background-color: var(--off-black);
  padding: 1vH 1vW;
  border-radius: 0 0 10px 10px;
  color: var(--orange)
}

.party {
  font-family: "Party";
}

.branding {
  font-family: "Party";
  font-size: 1.75em;
}

.teaching-artist {
  border-top: 1px solid var(--off-black);
}

.teaching-artist p {
  background-color: var(--off-black);
  padding: 1vH 1vW;
  border-radius: 0 0 10px 10px;
  color: var(--royal-blue);
}

/* === PROGRAMS === */
.rsvp {
  width: 65vW;
  border-top: 1px solid var(--off-black);
  border-radius: 0 30px 0 0;
  margin-top: 2vH;
}

.rsvp-button {
  margin-top: 2vH;
  background-color: var(--off-black);
  padding: 1vH 1vW;
  border: none;
  border-radius: 5px 5px 5px 5px;
  text-transform: uppercase;
  font-family: nimbus-sans-extended, sans-serif;
  color: var(--light-pink);
  height: 10vH;
  width: 15vW;
}

.rsvp-button:hover {
  color: var(--ivy);
}

.nav-heading {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.active-underline {
  width: 100%;
  height: 2px;
  background-color: var(--off-black);
  animation: underline ease-in;
  animation-duration: 1s;
}

.nav-heading-underline {
  width: 0%;
  height: 2px;
  background-color: var(--off-black);
}

.nav-container {
  width: 100%;
  transition-duration: 300ms;
}
.menu-list-container a {
  text-decoration: none;
}

.menu-list-container h4 {
  font-family: nimbus-sans-extended, sans-serif;
  font-style: normal;
  margin-left: 30px;
}

.menu-list-container h4 {
  font-family: nimbus-sans-extended, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--off-black);
  font-size: 3em;
  position: relative;
  text-decoration: none;
}

.menu-list-container h4:hover {
  color: var(--off-black);
  cursor: pointer;
}

.menu-list-container h4::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--light-pink);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.menu-list-container h4:hover::before {
  transform: scaleX(1);
}

.bigger-h4 {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: italic;
  font-size: 1.5em !important;
}

/* === MOBILE === */

@media only screen and (max-width: 900px) {

#images{
  display: flex;
}

#gallery {
  display: none;
}

.portfolio-img {
  width: 80vW;
}

.portfolio-page-title {
  margin-left: 0;
  padding: 1vH 2vW;
  max-width: 95vW;
}

.print {
  font-size: 2em;
}

}

.services-container {
  background-color: var(--light-pink);
  display: flex;
  flex-direction: column;
  height: calc(var(--app-height) - 150px);
  padding: 50px 30px;
}

.services-menu {
  display: flex;
  overflow-x: scroll;
  position: absolute;
  white-space: nowrap;
  width: 100%;
}

.services-menu::-webkit-scrollbar {
  display: none;
}

.services-container h3 {
  font-family: nimbus-sans-extended, sans-serif;
  font-style: normal;
  font-size: 20px;
}

.services-menu h3 {
  border-bottom: 1px solid white;
  cursor: pointer;
  height: 25px;
  padding-right: 50px;
  padding-bottom: 20px;
}

.services-menu h3:hover {
  /* border-bottom: 2px solid var(--bubblegum); */
  cursor: pointer;
}

.services-menu h3::before {
  content: "";
  position: relative;
  display: block;
  width: 100px;
  height: 2px;
  top: 44px;
  left: calc(50% - 50px);
  background-color: var(--bubblegum);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.services-menu h3:hover::before {
  transform: scaleX(1);
}

.underline::before {
  content: "";
  position: relative;
  display: block;
  width: 100px;
  height: 2px;
  top: 46px;
  left: calc(50% - 50px);
  background-color: var(--bubblegum);
  transform: scaleX(1) !important;
  transition: transform 0.3s ease;
}

.services-menu-container {
  display: flex;
  position: relative;
  height: 100px;
}

.services-container p {
  font-size: 20px;
  width: 50%;
}

.services-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--off-black);
}

.services-footer a {
  color: var(--off-black);
  text-decoration: none;
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  row-gap: 40px;
}

.project-preview {
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: none;
  transition-duration: 300ms;

}

.project-preview:hover {
  text-decoration: underline;
  transition-duration: 300ms;
}

.project-preview h3 {
  margin: 5px;
}

.project-preview img {
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, .2);  
  border-radius: 5px;
  object-fit: cover;
  height: 200px;
  width: 300px;
}

.project-list a {
  color: var(--off-black);
  text-decoration: none;
}

.project-container {
  background-color: var(--light-pink);
  display: flex;
  flex-direction: column;
  padding: 30px;
  row-gap: 20px;
}

.project-container h1 {
  margin-top: 5px;
}

.project-header-image {
  max-height: 80vH;
  max-width: 100%;
  object-fit: contain;
  object-position: 0 0;
}

.project-details {
  align-items: flex-end;
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}

.project-types {
  display: flex;
  column-gap: 20px;
  margin-bottom: 8px;
}

.project-types p {
  display: flex;
  margin-bottom: 0px;
  margin-top: 5px;
}

.feature-image img {
  height: var(--app-height);
  object-fit: cover;
  width: 100%;
}

.social-media-wrapper {
  display: flex;
  height: var(--app-height);
  justify-content: space-around;
  width: 100%;
}

.social-media-wrapper img {
  object-fit: contain;
  width: 25%;
}

.image-with-text-wrapper {
  display: flex;
  column-gap: 20px;
}

.image-with-text-wrapper div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.image-with-text-wrapper img {
  width: 50%;
}

.project-list {
  display: flex;
  column-gap: 20px;
  width: 100%;
  overflow-x: scroll;
}

