@media only screen and (max-width: 600px) {
  
    .tab-height {
      animation: none;
    }
    
    .tab-close {
      animation: none
    }
  
    /* === SPACING === */
  
    #desktop {
      display: none;
    }
  
    #mobile {
      display: flex;
    }
  
    .mobile-column {
      flex-direction: column;
    }
  
    .mobile-column-reverse {
      flex-direction: column-reverse;
    }
  
    .mobile-row {
      display: flex;
    }
  
    .mobile-center {
      justify-content: center;
    }
  
    .mobile-space-around {
      justify-content: space-around;
    }
  
    /* === NAV === */
  
    .primary-nav-el {
      width: 95vw;
      padding: 1vH 0vW !important;
    }
  
    .primary-nav-el h1 {
      font-size: 1.5em;
    }
  
    .logo-nav {
      width: 50px
    }
  
    .failspace-nav {
      display: none;
      height: 8vH !important;
    }
    .menu {
      height: 4vH;
    }
  
    .margin-top {
      margin-top: 1vH;
    }
  
    #nycsubnav {
      width: 95vW;
      text-align: center;
    }
  
    #dssubnav {
      width: 95vW;
      text-align: center;
    }
  
    /* === FOOTER === */
  
    .footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2vH 0vW;
      background-color: var(--light-pink);
    }
  
    .social-media-logo{
      width: 10vW;
      margin: 2vH 10vW 0vH 10vW;
    }
  
    .newsletter-form {
      width: 85vW;
      border-radius: 10px;
      padding: 2vH 3vW;
      display: flex;
    }
  
    .form-spacing{
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 84vW;
    }
  
    .newsletter-input {
      width: 80vW;
      margin-top: 2vH;
    }
    
    .footer-button {
      margin-top: 2vH;
    }
  
    .page-title {
      font-size: 28px;
      padding: 5px 30px;
    }
  
    /* === HOME === */
  
    .third {
      width: 80vW;
    }
  
    .intro-text {
      font-size: 2em;
    }
  
    .full-height {
      height: 85vH;
    }
  
    .mobile-full-width {
      width: 100%; 
    }
    /* === STEERING COMMITTEE === */
  
    .SC-container {
      width: 85vW;
    }
  
    .SC-member {
      border: none;
    }
  
    .bio {
      width: 85vW;
      padding-right: 2vW;
    }
  
    .portrait {
      width: 85vW;
    }
  
    .side-column {
      width: 85vW;
      border: none;
    }
    
    .info-component {
      margin-left: 0;
    }
    /* === CONTACT === */
  
    .contact-info {
      display: flex;
      flex-direction: column;
      width: 74vW;
    }
  
    .contact-input {
      width: 70vW;
    }
  
    .checkbox-name{
      width: 70vW;
  }
  
  /* === CONNECT === */
  
  .community-block{
    padding-bottom: 30px;
    width: 85vW;
  }
  
  .collaborations-block{
    width: 85vW;
  }
  
  /* === SUPPORT === */
  
  .support-bttn {
    width: 40vW;
  }
  
  .flex-order-three {
    order: 3;
    margin-top: 2vh;
  }
  
  .patreon-bttn {
    height: auto;
    margin-left: 2vW;
    margin-right: 2vW;
    margin-top: 2vH;
    width: 100%;
  }
  
  .patreon-tiers {
    width: auto;
  }
  
  .patreon-tier {
    background-color: var(--bubblegum);
    border-radius: 30px;
    width: 86vW;
    padding: 2vH 1vW 2vH 1vW;
    margin-top: 2vH;
  }
  
  .asterix-padding {
    width: 86vW;
    padding: 3vH 0vW;
  }
  
  
  /* === RSVP === */
  
  .rsvp {
    border: none;
  }
  
  .rsvp-button {
    width: 75vW;
  }
  
  /* === TABLES === */
  
  .service-list {
    width: 85vW;
  }
  
  .table-description {
    width: 100%;
  }
 
  .services-page {
    height: 100%;
  }
  
  /* === SERVICES === */
  
  .print {
    font-size: 2em;
    color: var(--off-black);
  }
  
  /* === PORTFOLIO === */
  
  .portfolio-title {
    padding: 1vH 2vW;
  }
  
  .portfolio-title:hover {
    transition: none;
  }
  
  .portfolio-desc {
    width: 80vW;
  }
  
  .portfolio-bttn {
    background-color: var(--off-black);
    border: none;
    color: var(--light-pink);
    padding: 2vH 1vW;
    border-radius: 10px;
    font-size: 2em;
  }
  
  .portfolio-logo {
    max-width: 25vW;
  }
  
  .portfolio-logo-lg {
    margin-top: 1vH;
    max-width: 60vW;
  }
  
  .CPR-logos {
    justify-content: space-around;
    width: 70vW;
    margin: 2vH 0vW;
  }
  
  #CPR {
    height: 14vH;
  }
  
  #small-title {
    height: 7vH;
  }

  h1 {
    font-size: 28px;
  }

  .nav-container {
    justify-content: flex-end;
  }

  .menu-list-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

  }

  .menu-list-container h4 {
    margin-left: 15px;
    border-bottom: 1px solid var(--light-pink);
  }

  .contact-container {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
  }

  .menu-header {
    padding: 10px 15px
  }

  .menu {
    font-size: 16px;
  }

  .wordmark {
    font-size: 20px;
  }

  .body {
    margin: 0;
    padding: 0;
  }

  .forty-eight-percent-w {
    width: auto;
  }

  .forty-eight-percent-w-mobile {
    margin-bottom: 10px;
  }
 
  
.services-container {
  height: 100%;
  padding: 20px 10px;
}

.services-menu::-webkit-scrollbar {
  display: none;
}

.services-container h3 {
  font-size: 16px;
}

.services-menu h3 {
  border-bottom: 1px solid white;
  cursor: pointer;
  padding-right: 20px;
  padding-bottom: 20px;
}

.services-menu h3::before {
  width: 80px;
  top: 44px;
  left: calc(50% - 50px);
}

.services-menu h3:hover::before {
  transform: scaleX(1);
}

.underline::before {
  width: 80px;
  top: 41px;
  left: calc(50% - 40px);
}

.services-menu-container {
  height: 90px;
}

.services-container p {
  font-size: 20px;
  width: 100%;
}

.project-preview:hover {
  text-decoration: underline;
}

.project-preview h3 {
  margin: 5px;
}

.project-preview img {
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, .2);  
  border-radius: 5px;
  object-fit: cover;
  height: 200px;
  width: 300px;
}

.project-list a {
  color: var(--off-black);
  text-decoration: none;
  width: auto;
}

.project-container {
  background-color: var(--light-pink);
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 20px;
}

.project-container h1 {
  margin-top: 5px;
}

.project-header-image {
  max-height: 80vH;
  max-width: 100%;
  object-fit: contain;
  object-position: 0 0;
}



.project-types {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 0px;
  margin-bottom: 8px;
}

.project-types p {
  white-space: nowrap;
}

.feature-image img {
  height: var(--app-height);
  object-fit: cover;
  width: 100%;
}

.social-media-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
  row-gap: 10px;
  width: 100%;
}

.social-media-wrapper img {
  object-fit: contain;
  width: 100%;
}

.image-with-text-wrapper {
  display: flex;
  flex-direction: column;
}

.image-with-text-wrapper div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-with-text-wrapper img{
  width: 100%;
}

.project-list {
  display: flex;
  column-gap: 20px;
  width: auto;
}

}